@import "~bootstrap/dist/css/bootstrap.css";
@import '~ngx-toastr/toastr';
body {
    margin: 0px !important;
}

* {
    font-family: Roboto, 'Helvetica Neue', Arial,sans-serif;
    box-shadow: none !important;
}


::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-corner {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #4f4f4f;
    border-radius: 10px;
}

.img-logo {
    width: 153px !important;
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #0096d6;
    border-color: #0096d6;
}

@media (min-width: 1000px) {
    .container {
        min-width: 920px;
    }
}

@media (min-width: 1100px) {
    .container {
        min-width: 1020px;
    }
}

@media (min-width: 1200px) {
    .container {
        min-width: 1120px;
    }
}

@media (min-width: 1300px) {
    .container {
        min-width: 1220px;
    }
}

@media (min-width: 1400px) {
    .container {
        min-width: 1320px;
    }
}

@media (min-width: 1500px) {
    .container {
        width: 1420px;
    }
}

@media (min-width: 1600px) {
    .container {
        min-width: 1520px;
    }
}

@media (min-width: 1700px) {
    .container {
        min-width: 1620px;
    }
}

@media (min-width: 1800px) {
    .container {
        min-width: 1720px;
    }
}

@media (min-width: 1900px) {
    .container {
        min-width: 1820px;
    }
}


/* Custom CSS */

.validator-alert,
.invalid-feedback {
    color: red;
    margin: 0;
    font-size: 12px;
}

.btn {
    min-width: 100px;
    height: 40px;
    margin-left: 10px !important;
}

.btn-outline-primary {
    border: 2px solid;
}

.paddingH {
    padding-left: 10px;
    padding-right: 10px;
}

.marginV {
    margin-top: 10px;
    margin-bottom: 10px;
}

.right {
    float: right;
    margin-right: 20px;
    padding-top: 8px !important;
    font-size: 14px;
}

.left {
    float: left;
    margin-left: 20px;
}

.center {
    text-align: center;
}

.validator-alert {
    color: red;
    padding: 5px;
}


/* Input fields styles */

.p-multiselect .p-multiselect-label {
    padding: 5px;
}

.p-multiselect {
    width: 80%;
    border: 1px solid #4f4f4f;
    border-radius: 3px;
}
span.p-multiselect-trigger-icon.pi.pi-chevron-down {
    margin-right: -15px;
}

.p-dropdown {
    width: 80%;
    border: 1px solid;
    border-radius: 3px;
}

.p-dropdown-panel .p-dropdown-items {
    background-color: #4f4f4f08;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-size: 14px;
}

.p-dropdown .p-dropdown-trigger {
    width: 20px;
    padding-right: 10px;
}

.p-dropdown:not(.p-disabled).p-focus {
    border: 1px solid #4f4f4f;
}

.p-dropdown .p-dropdown-label {
    padding: 5px;
}

.p-inputtext {
    padding: 5px;
    width: 80%;
    border: 1px solid #4f4f4f;
    border-radius: 3px;
    text-transform: uppercase;
}


input[type=text]{
    text-transform: uppercase;
}
#inputFileType {
    text-transform: none !important;
}


/* dialog popup */

.dialog-mask>div {
    background-color: #000000de;
}

.p-dialog .p-dialog-footer button {
    height: 40px;
}

.p-dialog .p-dialog-header-icons {
    display: none !important;
}


/* Tab Menu styles */

.p-tabmenu .p-tabmenu-nav {
    border-bottom: 2px solid;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: #4f4f4f !important;
    color: white !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border-color: #4f4f4f !important;
}

.p-tabmenu-nav .p-menuitem-text {
    font-size: 15px;
    font-weight: 550;
    line-height: 0.5 !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
}

p-sorticon.p-element {
    float: right;
    padding-right: 10px;
}

button.p-column-filter-menu-button.p-link,
button.p-column-filter-clear-button.p-link,
button.p-column-filter-clear-button.p-link.p-hidden-space {
    display: none;
}


/* accordian tabs */

.p-accordion .p-accordion-header .p-accordion-header-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 14px;
    font-size: 14px;
    box-shadow: none !important;
}


/* Start Grid styles */

#dynamicGrid {
    margin-bottom: 50px;
}

.p-datatable-wrapper {
    border-left: 1px solid;
    border-right: 1px solid;
}


.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    
    font-size: 12px;
    font-style: italic;
    justify-content: flex-end;
    border: 1px solid;
}

button.p-ripple.p-element.p-paginator-element.p-link {
    min-width: 1.5rem;
    min-height: 1.5rem;
}

button.p-ripple.p-element.p-paginator-element.p-link span {
    height: 12px;
}

.p-paginator li.p-ripple.p-element.p-dropdown-item {
    padding: 0 0 0 10px;
}


.p-dropdown-items-wrapper.ng-tns-c39-5 {
    min-height: 60px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding: 0 5px;
}

.p-paginator .p-dropdown-trigger-icon.pi.pi-chevron-down {
    padding: 3px;
}

.p-paginator .p-dropdown {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: auto !important;     
}

.p-paginator .p-paginator-pages .p-paginator-page {
    border-radius: 0%
}


.p-datatable .p-datatable-thead>tr>th {
    background-color: #4f4f4f;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 3px;
    text-align: center;
    border: 1px solid #e1f0ff;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #fff;
}

.p-datatable .p-sortable-column.p-highlight,
.p-sortable-column-icon.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #4f4f4f !important;
}

.p-fluid .p-inputtext {
    padding: 5px;
    height: 25px;
    width: 90%;
    margin: 0 10px;
}

#dynamicGrid .ui-table .ui-table-thead>tr>th>div {
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-datatable .p-datatable-tbody>tr>td {
    font-size: 14px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 10px;
    color: black;
    font-weight: 400;
    border: none !important;
    border-right: 1px solid #4f4f4f !important;
    text-overflow: ellipsis;
    vertical-align: top !important;
}

.p-datatable .p-datatable-tbody>tr:nth-child(even) {
    background-color: #4f4f4f15;
}

#dynamicGrid .edit-column {
    position: sticky;
    right: 0px !important;
}

#dynamicGrid .checkbox-column {
    position: sticky;
    left: 0px !important;
}

#dynamicGrid .ui-paginator {
    margin-top: 10px !important;
    background-color: white !important;
    border: none !important;
    float: right !important;
}

#dynamicGrid .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background-color: #0096d6 !important;
    color: white !important;
}

#dynamicGrid .ui-multiselect .ui-multiselect-panel {
    left: -292px !important;
    right: 0px !important;
}

#dynamicGrid .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
    color: black !important;
    background-color: white !important;
}

#dynamicGrid .ui-chkbox .ui-chkbox-box.ui-state-active {
    border-color: #0096d6 !important;
    background-color: #0096d6 !important;
    color: #ffffff !important;
}

.p-datatable .ui-table table {
    width: 100% !important;
}

.p-datatable .ui-table {
    max-height: 700px !important;
}

.p-datatable .ui-table-scrollable-header,
.ui-table-scrollable-footer {
    position: sticky;
    background-color: white;
    z-index: 9999;
}

#dynamicGrid .ui-table-scrollable-header-box {
    margin-right: 60px !important;
}

#dynamicGrid .ui-table-scrollable-body {
    max-height: calc(100vh - 280px) !important;
}

#dynamicGrid .excelIcon {
    font-size: 1.5em;
    cursor: pointer;
    background-color: #0096d6;
    color: white;
    border-radius: 5px;
    padding: 6px;
    margin-left: 10px;
    top: -7px;
    position: relative;
}

@media screen and (min-width: 1000px) {
    .p-datatable .ui-table-scrollable-body {
        max-height: calc(100vh - 370px) !important;
    }
    .cf {
        margin-top: 140px !important;
    }
}

@media screen and (min-width: 1052px) {
    .p-datatable .ui-table-scrollable-body {
        max-height: calc(100vh - 320px) !important;
    }
    .cf {
        margin-top: 110px !important;
    }
}

@media screen and (min-width: 1407px) {
    .p-datatable .ui-table-scrollable-body {
        max-height: calc(100vh - 300px) !important;
    }
    .cf {
        margin-top: 80px !important;
    }
}


/* END Grid Styles */
.p-dialog {
    width: 50vw;
}
@media (max-width: 768px) {
    /* CSS that should be displayed if width is equal to or less than 768px goes here */
    .p-dialog {
        width: 90vw;
    }
}

@media (max-width: 400px) {
    /* CSS that should be displayed if width is equal to or less than 768px goes here */
    .p-dialog {
        width: 98vw;
    }
}